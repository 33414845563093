import router from "./router";
import store from "./store";

router.beforeEach((to, from, next) => {
    store.state.showLoading = true;
    if (to.meta.requiresAuth) {
        if (store.state.token) {
        next();
        } else {
        next({ name: "login" });
        }
    } else {
        next();
    }
});

router.afterEach(() => {
    store.state.showLoading = false;
});