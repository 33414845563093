import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import money from 'v-money3'
import UUID from "vue3-uuid"
import App from './App.vue'
import router from './router'
import store from './store'
import './permission.js'
import { createI18n } from 'vue-i18n'
import es from './i18n/es'

const messages = {
    es
  }
const i18n = createI18n({
    locale: 'es', // set locale
    fallbackLocale: 'es', // set fallback locale
    messages
})
  
const app = createApp(App)
app.use(store)
app.use(router)
app.use(UUID)
app.use(money)
app.use(i18n)
app.use(ElementPlus)

app.mount('#app')