<template>
    <el-container style="width: 100%;">
        <el-row :gutter="4" style="width:100%;">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <img src="@/assets/logo-fundacion.svg" />
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                <el-row>
                    <el-col :span="24">
                        <h2 style="color:white;">Mi Usuario</h2>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <h3 style="color:white;">Portal Transaccional</h3>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <h4 style="color:white;">Cambio de Contraseña</h4>
                    </el-col>
                </el-row>
                <el-form label-position="top" v-model="form" class="login-form">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Contraseña">
                                <el-input v-model="form.password"  type="password" placeholder="Digite La Contraseña"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Confirme su Contraseña">
                                <el-input v-model="form.repassword" type="password" placeholder="Vuelva a Digitar La Contraseña"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Correo Electrónico">
                                <el-input readonly type="email" v-model="form.email" placeholder="Digite Su Contraseña"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="4">
                        <el-col :span="12">
                            <el-form-item>
                                <el-button type="primary" @click="change">Cambiar Contraseña</el-button>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item>
                                <el-button type="success" @click="login">Cancelar</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>                                       
                </el-form>
            </el-col>
        </el-row>
    </el-container>
</template>
<script>
import { changePassword } from '@/api/transaccional'
import { ElMessageBox } from 'element-plus'

export default {
    name: 'ChangePasswordView',
    data() {
        return {
            form: {
                password: '',
                repassword: '',
                email: '',
                link: ''
            },
        }
    },
    beforeMount() {
        this.form.email = this.$route.params.email
        this.form.link = this.$route.params.link
    },
    methods: {
        login() {
            this.$router.push('/login')
        },
        change() {
            const loading = this.$loading({
                lock: true,
                text: 'Asignando Contraseña',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            changePassword(this.form.email, this.form.password, this.form.link).then(() => {
                loading.close()
                ElMessageBox.alert('Contraseña Cambiada con Exito', 'Cambio de Contraseña', 
                {
                    confirmButtonText: 'Aceptar',
                    type: 'success',
                }).then(() => {
                    console.log("Redireccionando a Login")
                    this.$router.push('/login')
                })
            }).catch(() => {
                loading.close()
                ElMessageBox.alert('No se pudo realizar el cambio de contraseña', 'Error', {
                    confirmButtonText: 'Aceptar',
                    callback: () => {
                        this.$router.push('/login')
                    }
                })
            })
        }
    },
}

</script>