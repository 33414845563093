<template>
    <el-container class="container">
        <el-main>
            <el-row :gutter="4" style="width:100%;">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <img src="@/assets/logo-fundacion.svg" />
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                    <el-row>
                        <el-col :span="24">
                            <h2 style="color:white;">Mi Usuario</h2>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <h3 style="color:white;">Portal Transaccional</h3>
                        </el-col>
                    </el-row>
                    <el-card>
                        <template #header>
                            <div class="card-header">
                                <span>Ingreso al Portal</span>
                            </div>
                        </template>
                        <el-form label-position="top" v-model="form" class="login-form">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="Tipo de Documento" style="color: white;">
                                        <el-select v-model="form.id_identificacion" clearable
                                            placeholder="Seleccione el Tipo de Documento" style="width:100%;">
                                            <el-option v-for="item in documentType" :key="item.id" :label="item.name"
                                                :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="Número de Documento">
                                        <el-input v-model="form.id_persona" placeholder="Digite Su Número de Documento" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="Contraseña">
                                        <el-input type="password" v-model="form.password"
                                            placeholder="Digite Su Contraseña" />
                                    </el-form-item>
                                    <el-form-item>
                                        <el-link type="primary" href="recovery">¿Olvidó su contraseña?</el-link>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="4">
                                <el-col :span="12">
                                        <el-button :disabled="form.id_identificacion === null || form.id_persona === null || form.password === null" type="primary" @click="login">Ingresar</el-button>
                                </el-col>
                                <el-col :span="12">
                                        <el-button type="primary" @click="register">Registrarse</el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-card>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import { auth } from '@/api/transaccional'
import { ElMessageBox } from 'element-plus'

export default {
    name: 'LoginView',
    data() {
        return {
            form: {
                id_identificacion: null,
                id_persona: null,
                password: null
            },
            documentType: [
                {
                    id: 3,
                    name: 'Cédula de Ciudadanía'
                },
                {
                    id: 4,
                    name: 'N.I.T'
                },
            ]
        }
    },
    methods: {
        login() {
            const loading = this.$loading({
                lock: true,
                text: 'Cargando...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            auth(this.form.id_identificacion, this.form.id_persona, this.form.password).then(response => {
                loading.close()
                console.log(response)
                let userData = response.data
                this.$store.state.isAutenticated = true
                this.$store.state.id_identificacion = this.form.id_identificacion
                this.$store.state.id_persona = this.form.id_persona
                this.$store.state.token = response.data.token
                this.$store.state.usua_nombre = response.data.usua_nombre
                this.$store.state.usua_apellido = response.data.usua_apellido
                this.$store.state.usua_email = response.data.usua_email
                userData.id_identificacion = this.form.id_identificacion
                userData.id_persona = this.form.id_persona
                localStorage.setItem('_fapmut', JSON.stringify(userData))
                this.$router.push({ path: '/vistas/creditos' })
            }).catch(error => {
                loading.close()
                console.log(error)
                this.$store.state.isAutenticated = false
                ElMessageBox.alert('Usuario o contraseña incorrectos', 'Error', {
                    confirmButtonText: 'Aceptar',
                    type: 'error'
                })
            })
        },
        register() {
            this.$router.push({ path: '/register' })
        }
    }
}
</script>
<style scoped>
.container {
    height: 98vh;
    width: 100vw;
    background-image: url('~@/assets/fondo.png');
}

.login-form {
    --el-text-color-regular: rgb(172, 166, 166);
}

.el-form-item__label {
    color: white !important;
}
</style>