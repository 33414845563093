import request from '@/utils/request'
export function auth (t, d, p) {
    return request({
        url: '/a/' + t + '/' + d + '/' + p,
        method:'get',
    })
}

export function register (i, d, e) {
    return request({
        url: '/g/' + i + '/' + d + '/' + e,
        method:'get',
    })
}

export function recovery (i, d, e) {
    return request({
        url: '/r/' + i + '/' + d + '/' + e,
        method:'get',
    })
}

export function validateLink (l) {
    return request({
        url: '/l/' + l,
        method:'get',
    })
}

export function changePassword (e, p, l) {
    return request({
        url: '/cp/' + e + '/' + p + '/' + l,
        method:'get',
    })
}

export function cartera(t, d) {
    return request({
        url: '/ic/' + t + '/' + d,
        method:'get',
    })
}

export function historia(t, d) {
    return request({
        url: '/ih/' + t + '/' + d,
        method:'get',
    })
}

export function fianzas(t, d) {
    return request({
        url: '/if/' + t + '/' + d,
        method:'get',
    })
}

export function extracto(i) {
    return request({
        url: '/ex/' + i,
        method:'get',
    })
}

export function liquidar(a,i,c,f) {
    return request({
        url: '/lc/' + a + '/' + i + '/' + c + '/' + f,
        method:'get',
    })
}

export function getReferenciaPago(i) {
    return request({
        url: '/wompi/ref/' + i,
        method:'get',
    })
}

export function getCertificadoPazySalvo(c) {
    return request({
        url: '/cps/' + c,
        method:'get',
        responseType: 'blob'
    })
}

export function getCertificadoComercial(i,p) {
    return request({
        url: '/ccc/' + i + '/' + p,
        method:'get',
        responseType: 'blob'
    })
}