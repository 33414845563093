import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import getters from './getters'

const store = createStore({
    state: {
        id_identificacion: null,
        id_persona: null,
        sidebar: true,
        language: 'es',
        size: 'medium',
        device: 'desktop',
        isAutenticated: false,
        usua_nombre: null,
        usua_apellido: null,
        usua_email: null,
        token: null,
        showLoading: true,
        socket: {
            isConnected: false,
            message: "",
            reconnectError: false,
            heartBeatInterval: 50000,
            heartBeatTimer: 0
        }
    },
    mutations: {
        setShowLoading(state, value) {
            state.showLoading = value;
        }
    },
    getters,
    plugins: [createPersistedState({ key: "fapmiusuario" })],
})

export default store