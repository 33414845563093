const getters = {
    sidebar: state => state.sidebar,
    language: state => state.language,
    size: state => state.size,
    device: state => state.device,
    isAutenticated: state => state.isAutenticated,
    id_identificacion: state => state.id_identificacion,
    id_persona: state => state.id_persona,
    usua_nombre: state => state.usua_nombre,
    usua_apellido: state => state.usua_apellido,
    usua_email: state => state.usua_email,
    showLoading: state => state.showLoading
  }
  export default getters