<template>
        <el-container class="container">
            <el-header style="height: 107px; text-align: left;">
                <img src="@/assets/logo-fundacion.svg" />
            </el-header>
            <el-main>
                <MenuComponent />
                <router-view></router-view>
            </el-main>
        </el-container>
</template>
<script>
/* import {
    Document,
    Menu as IconMenu,
    Location,
    Setting,
    Close
} from '@element-plus/icons-vue' */
import { ElMessageBox } from 'element-plus';
import MenuComponent from './MenuComponent.vue'

export default {
    name: 'LayoutView',
    components: {
/*         Document,
        IconMenu,
        Location,
        Setting,
        Close, */
        MenuComponent
    },
    data() {
        return {
            activeIndex: '1',
            isCollapse: false,
            wsconnection: null,
            showMenu: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            const icons = document.querySelectorAll(".icon")
            icons.forEach((icon) => {
                icon.addEventListener("click", () => {
                    icon.classList.toggle("open")
                    this.showMenu = !this.showMenu
                })
            });
        })
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        openMenu() {
            const icons = document.querySelectorAll(".icon")
            icons.forEach((icon) => {
                icon.addEventListener("click", () => {
                    icon.classList.toggle("open")
                    this.showMenu = !this.showMenu
                })
            });
        },
        onResize() {
            if (window.innerWidth < 768) {
                this.isCollapse = true
            } else {
                this.isCollapse = false
            }
        },
        wssocket() {
            console.log("Starting connection to WebSocket Server");
            this.connection = new WebSocket("wss://localhost:3000");

            this.connection.onmessage = function (event) {
                console.log("look, I got something from server");
                console.log(event.data);
            };

            this.connection.onopen = function (event) {
                console.log(event);
                console.log("Successfully connected to the echo websocket server...");
            };
        },
        handleSelect(key, keyPath) {
            console.log("key:", key, ", keyPath:", keyPath)
            switch (key) {
                case '1':
                    this.$router.push({ path: '/vistas/creditos' })
                    break;
                case '2':
                    this.$router.push({ path: '/vistas/historia' })
                    break;
                case '3':
                    this.$router.push({ path: '/vistas/fianzas' })
                    break;
                case '4':
                    this.$router.push({ path: '/vistas/personal' })
                    break;
                case '5':
                    this.cerrarSesion()
                    break;
            }
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath)
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath)
        },
        cerrarSesion() {
            ElMessageBox.confirm(
                'Seguro de cerra la sesión?',
                'Atención',
                {
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    type: 'warning',
                }
            )
                .then(() => {
                    localStorage.removeItem("_fapmut")
                    this.$router.push({ path: '/login' })
                })
                .catch(() => {
                })
        }
    },
}
</script>
<style scoped>
.container {
   height: 98vh;
   width: 100vw;
}

</style>