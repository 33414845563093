<template>
    <el-container style="width: 100%;">
        <el-row :gutter="4" style="width:100%;">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <img src="@/assets/logo-fundacion.svg" />
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                <el-row>
                    <el-col :span="24">
                        <h2 style="color:white;">Mi Usuario</h2>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <h3 style="color:white;">Portal Transaccional</h3>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <h4 style="color:white;">Registro</h4>
                    </el-col>
                </el-row>
                <el-form label-position="top" v-model="form" class="login-form">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Tipo de Documento">
                                <el-select v-model="form.id_identificacion" clearable placeholder="Seleccione el Tipo de Documento" style="width:100%;">
                                    <el-option
                                        v-for="item in documentType"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Número de Documento">
                                <el-input v-model="form.id_persona"  placeholder="Digite Su Número de Documento"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Correo Electrónico">
                                <el-input type="email" v-model="form.email" placeholder="Digite Su Contraseña"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="4">
                        <el-col :span="12">
                            <el-form-item>
                                <el-button type="primary" @click="registrar">Registrarme</el-button>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item>
                                <el-button type="success" @click="login">Cancelar</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>                                       
                </el-form>
            </el-col>
        </el-row>
    </el-container>
</template>
<script>
import { validateLink } from '@/api/transaccional'

export default {
    name: 'SetPasswordView',
    data() {
        return {
            form: {
                id_identificacion: '',
                id_persona: '',
                email: ''
            },
            documentType: [
                {
                    id: 2,
                    name: 'Tarjeta de Identidad'
                },
                {
                    id: 3,
                    name: 'Cédula de Ciudadanía'
                }
            ]
        }
    },
    beforeMount() {
        const link = this.$route.params.link
        if (link) {
            console.log('Enlace: voy a validar el link:', link)
            const loading = this.$loading({
                lock: true,
                text: 'Validando...',
            })
            validateLink(link).then((response) => {
                loading.close()
                console.log('Enlace: el link es válido')
                const email = response.data
                this.$alert('El vínculo es válido, por favor proceda a asignar su contraseña', 'Ruta válida', 'success').then(() => {
                    this.$router.push({ path: `/chgpass/${email}/${link}` })
                })
            }).catch(() => {
                loading.close()
                this.$alert('Se presentó un error al validar el vínculo, por favor reintente mas tarde', 'Ruta no válida', 'error').then(() => {
                    this.$router.push({ path: 'login' })
                })
            })
        }
    },
    methods: {
        login() {
            this.$router.push({ path: '/login' })
        },
    },
}

</script>