<template>
    <el-container class="container">
        <el-row :gutter="4" style="width:100%;">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <img src="@/assets/logo-fundacion.svg" />
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                <el-row>
                    <el-col :span="24">
                        <h2 style="color:white;">Mi Usuario</h2>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <h3 style="color:white;">Portal Transaccional</h3>
                    </el-col>
                </el-row>
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>Registro de Usuario</span>
                        </div>
                    </template>
                    <el-form ref="formRegistrar" :model="form" label-position="top" class="login-form">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="Tipo de Documento" prop="id_identificacion">
                                    <el-select v-model="form.id_identificacion" clearable
                                        placeholder="Seleccione el Tipo de Documento" style="width:100%;">
                                        <el-option v-for="item in documentType" :key="item.id" :label="item.name"
                                            :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="Número de Documento" prop="id_persona">
                                    <el-input v-model="form.id_persona" placeholder="Digite Su Número de Documento" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="Correo Electrónico" prop="email" :rules="[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese la dirección de correo registrada',
                                        trigger: 'blur',
                                    },                                    
                                    {
                                        type: 'email',
                                        message: 'Por favor ingrese una dirección de correo válida',
                                        trigger: ['blur', 'change'],
                                    },
                                ]">
                                    <el-input v-model="form.email"
                                        placeholder="Ingrese su Correo Electrónico" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="4">
                            <el-col :span="12">
                                   <el-button
                                        :disabled="form.id_identificacion === null || form.id_persona === null || form.email === null"
                                        type="primary" @click="registrar">Registrarme</el-button>
                            </el-col>
                            <el-col :span="12">
                                    <el-button type="error" @click="login">Cancelar</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </el-container>
</template>
<script>
import { ElMessageBox } from 'element-plus'
import { register } from '@/api/transaccional'

export default {
    name: 'RegisterView',
    data() {
        return {
            form: {
                id_identificacion: null,
                id_persona: null,
                email: null
            },
            documentType: [
                {
                    id: 3,
                    name: 'Cédula de Ciudadanía'
                },
                {
                    id: 4,
                    name: 'N.I.T'
                },
            ]
        }
    },
    methods: {
        login() {
            this.$router.push({ path: '/login' })
        },
        registrar() {
            this.$refs.formRegistrar.validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Registrando...',
                    })
                    register(this.form.id_identificacion, this.form.id_persona, this.form.email).then((response) => {
                        loading.close()
                        if (response.data[0]) {
                            ElMessageBox.alert('Para finalizar su registro, por favor siga el vínculo que se envió a su correo electrónico', 'Registro Exitoso', {
                                confirmButtonText: 'Aceptar',
                                type: 'success',
                            }).then(() => {
                                this.$router.push('/login')
                            })
                        } else {
                            ElMessageBox.alert('Se presentó un error al registrar la información: ' + response.data[1], {
                                confirmButtonText: 'Aceptar',
                                type: 'error',
                            }).then(() => {
                                this.$router.push('/login')
                            })
                        }
                    }).catch(() => {
                        loading.close()
                        ElMessageBox.alert('Se presentó un error al registrar la información, por favor reintente mas tarde', 'Error en el Registro', {
                            confirmButtonText: 'Aceptar',
                            type: 'error',
                        }).then(() => {
                            this.$router.push('/login')
                        })
                    })
                }
            })
        }
    }
}

</script>
<style scoped>
.container {
    height: 98vh;
    width: 100vw;
}

.login-form {
    --el-text-color-regular: rgb(172, 166, 166);
}


.el-form-item__label {
    color: white !important;
}
</style>