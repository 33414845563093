<template>
  <el-container>
    <div class="menu hidden-sm-and-down">
      <el-menu mode="horizontal" default-active="1" active-text-color="#409EFF" @select="handleMenuClick" class="transparent-bg white-text">
        <el-menu-item index="1">Mi Cartera</el-menu-item>
        <el-menu-item index="2">Mi Historia</el-menu-item>
        <el-menu-item index="3">Mis Fianzas</el-menu-item>
        <el-menu-item index="4">Mis Datos Personales</el-menu-item>
        <el-sub-menu index="5" class="white-text">
          <template #title>
            <span>Certificado</span></template>
          <el-menu-item index="51">Comercial</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="99">Cerrar Sesión</el-menu-item>
      </el-menu>
    </div>
    <nav class="navbar hidden-md-and-up">
      <div class="hamburger hidden-md-and-up" @click="toggleMenu">
        <div class="line"></div>
        <div class="line middle"></div>
        <div class="line"></div>
      </div>

      <div class="navbar-collapse" :class="{ 'show': showMenu }">
        <el-menu mode="vertical" default-active="1" active-text-color="#409EFF" @select="handleHMenuClick" class="transparent-bg white-text">
        <el-menu-item index="1">Mi Cartera</el-menu-item>
        <el-menu-item index="2">Mi Historia</el-menu-item>
        <el-menu-item index="3">Mis Fianzas</el-menu-item>
        <el-menu-item index="4">Mis Datos Personales</el-menu-item>
        <el-sub-menu index="5">
          <template #title>
            <span>Certificado</span></template>
          <el-menu-item index="51">Comercial</el-menu-item>
        </el-sub-menu>        
        <el-menu-item index="99">Cerrar Sesión</el-menu-item>
      </el-menu>
      </div>
    </nav>
  </el-container>
</template>
<script>
import { ElMessageBox } from 'element-plus';
import { mapGetters } from 'vuex'
import { getCertificadoComercial } from '@/api/transaccional'

export default {
  name: 'MenuComponent',
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    handleMenuClick(index) {
      if (index == 1) {
        this.$router.push({ path: '/vistas/creditos' })
      }
      if (index == 2) {
        this.$router.push({ path: '/vistas/historia' })
      }
      if (index == 3) {
        this.$router.push({ path: '/vistas/fianzas' })
      }
      if (index == 4) {
        this.$router.push({ path: '/vistas/persona' })
      }
      if (index == 51) {
        this.certificadoComercial()
      }
      if (index == 99) {
        this.cerrarSesion()
      }
    },
    handleHMenuClick(index) {
      this.toggleMenu()
      if (index == 1) {
        this.$router.push({ path: '/vistas/creditos' })
      }
      if (index == 2) {
        this.$router.push({ path: '/vistas/historia' })
      }
      if (index == 3) {
        this.$router.push({ path: '/vistas/fianzas' })
      }
      if (index == 4) {
        this.$router.push({ path: '/vistas/persona' })
      }
      if (index == 51) {
        this.certificadoComercial()
      }
      if (index == 99) {
        this.cerrarSesion()
      }
    },    
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    certificadoComercial() {
      ElMessageBox.confirm(
        'Seguro de descargar el Certificado Comercial. Continuar?',
        'Confirmar',
        {
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          type: 'warning',
        }
      )
      .then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Generando certificado comercial...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        getCertificadoComercial(this.id_identificacion, this.id_persona).then(response => {
          loading.close();
            const filename = response.headers['content-disposition'].split(';')[1].trim().split('=')[1];
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          }).catch((error) => {
            loading.close();
            this.$message.error("Error generando certificado comercial: " + error);
          })
        })
      .catch((error) => {
        this.$message.error("Error generando certificado comercial: " + error);
      })
    },
    cerrarSesion() {
      ElMessageBox.confirm(
        'Seguro de cerra la sesión?',
        'Atención',
        {
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          type: 'warning',
        }
      )
        .then(() => {
          localStorage.removeItem("_fapmut")
          this.$router.push({ path: '/login' })
        })
        .catch(() => {
        })
    }
  },
  computed: {
        tableRowClassName(row) {
            if (row.dias_mora > 0) {
                return 'warning-row';
            } else {
                return '';
            }
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        ...mapGetters([
          'id_identificacion',
          'id_persona',
        ])
    },  
}
</script>
<style scoped>
nav {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: transparent !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

nav h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

nav .hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

nav .hamburger .line {
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

nav .hamburger .line.middle {
  width: 70%;
  margin: 0 auto;
}

nav .hamburger.active .line.middle {
  width: 100%;
}

.navbar-collapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  right: 60%;
  background-color: #303373;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 99;
  padding: 20px;
}

.navbar-collapse.show {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.navbar-collapse ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.navbar-collapse a {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  transition: all 0.2s ease;
}

.navbar-collapse a:hover {
  color: #666;
  background-color: rgba(0, 0, 0, 0.1);
}


.menu {
  width: 80%;
  padding: 10px;
}
.transparent-bg {
  width: 100%;
  background-color: transparent !important;
}
.white-text .el-menu-item,
.white-text .el-sub-menu__title span, 
.white-text .el-icon svg {
  color: #fff;
}

.is-opened span{
  background-color: #fff;
  color: #000 !important;
}


/* @media (min-width: 768px) {
  .hidden-md-and-up {
    display: none;
  } 
}*/
</style>