import { createRouter, createWebHistory } from "vue-router";
import LayoutView from "@/components/LayoutView.vue";
import LoginView from "@/components/LoginView.vue";
import RegisterView from "@/components/RegisterView.vue";
import SetPasswordView from "@/components/SetPasswordView.vue";
import ChangePasswordView from "@/components/ChangePasswordView.vue";
import RecoveryView from "@/components/RecoveryPasswordView.vue";

const routes = [
    {
        path: "/vistas",
        name: "Layout",
        component: LayoutView,
        redirect: "/creditos",
        children: [
            {
                path: "creditos",
                component: () => import("@/views/cartera/CreditosView.vue"),
                name: "creditos",
                meta: { title: "Creditos", requiresAuth: true }
            },
            {
                path: "historia",
                component: () => import("@/views/cartera/HistoriaView.vue"),
                name: "Historia",
                meta: { title: "Historia", requiresAuth: true }
            },
            {
                path: "fianzas",
                component: () => import("@/views/cartera/FianzasView.vue"),
                name: "Fianzas",
                meta: { title: "Fianzas", requiresAuth: true }
            },
            {
                path: "persona",
                component: () => import("@/views/info/PersonaView.vue"),
                name: "Persona",
                meta: { title: "Persona", requiresAuth: true }
            },
            {
                path: "wompi",
                name: "wompi",
                component: () => import("@/views/wompi/WompiView.vue"),
                meta: { title: "Wompi", requiresAuth: true }
            },
        ]
    },
    {
        path: "/register",
        name: "register",
        component: RegisterView
    },
    {
        path: "/recovery",
        name: "recovery",
        component: RecoveryView
    },    
    {
        path: "/chgpass/:email/:link",
        name: "chgpass",
        component: ChangePasswordView
    },    
    {
        path: "/login",
        name: "login",
        component: LoginView
    },
    {
        path: "/l/:link",
        name: "enlace",
        component: SetPasswordView
    },
    {
        path: "/",
        name: "root",
        redirect: "login"
    }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    mode: "history",
    history: createWebHistory(),
    routes, // short for `routes: routes`
  })

  export default router;