<template>
  <el-container>
    <router-view></router-view>
  </el-container>
</template>

<script>
import { ElLoading } from 'element-plus'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['showLoading'])
  },
  watch: {
    showLoading(show) {
      console.log("ShowLoading: ", show)
      if (show) {
          const loading = ElLoading.service({
          lock: true,
          'svg-view-box':"-10, -10, 40, 40",
          background: 'rgba(122, 122, 122, 0.8)'
        });
        setTimeout(() => {
          loading.close();
          this.$store.commit('setShowLoading', false);
        }, 1000);

      }
    }
  },
  beforeCreate() {
    let userDataJSON = localStorage.getItem('_fapmut');
    if (userDataJSON) {
      let userData = JSON.parse(userDataJSON);
      console.log("UserData: ", userData);
      this.$store.state.isAutenticated = true;
      this.$store.state.id_identificacion = userData.id_identificacion;
      this.$store.state.id_persona = userData.id_persona;
      this.$store.state.token = userData.token;
      this.$store.state.usua_nombre = userData.usua_nombre;
      this.$store.state.usua_apellido = userData.usua_apellido;
      this.$store.state.usua_email = userData.usua_email;
      // console.log("Path:", this.$route.path);
      // this.$router.push({ path: this.$route.path });
    }
  },
  methods: {
    toCurrency(value) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formatDateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    toThousandFilter(num) {
      return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
    },
    fm_truncate(value, length) {
      if (!value) return ''
      value = value.toString()
      if (value.length > length) {
        return value.substring(0, length) + '...'
      } else {
        return value
      }
    }    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url('~@/assets/fondo.png');
  background-repeat: repeat;
  background-position: center;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
}
</style>
